import classnames from 'classnames';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import { useState } from 'react';
import { Button, Col, Form, Nav, NavItem, NavLink, Row } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom";
import { ReactComponent as EndGame } from '../../assets/EndGame.svg';
import { ReactComponent as SignInGoogle } from '../../assets/sign-ingoogle.svg';
import { postEndGameApi } from '../../helpers/endgameApi';
import { auth } from '../../services/firestore';
import EndGameButton from '../EndgameButton/EndGameButton';
import styles from './Auth.module.scss';
import classNames from 'classnames';

interface FuncProps {
  //here you can declare the return type (here is void)
  onSuccess: (newUser: any) => void;
  modalView: boolean;
  setIsLoggingIn:(isLoggingIn: boolean) => void
}

// const auth = getAuth();
const LoginScreen = (props: FuncProps) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleFirebaseAuthError = (errorCode: string) => {
    if(errorCode === 'auth/invalid-login-credentials') {
      setErrorMessage('Invalid Credentials');
    }
    else if(errorCode === 'auth/email-already-in-use') {
      setErrorMessage('Email already in use');
    } else if(errorCode === 'auth/missing-password') {
      setErrorMessage('Password Required');
    }  else if(errorCode === 'auth/weak-password') {
      setErrorMessage('Password must be at least 6 characters');
    } else {
      setErrorMessage('An error occurred while logging in');
    }
    console.error(errorCode);
  }

  const handleGoogleLogin = async () => {
    try {
      props.setIsLoggingIn(true);
      const provider = new GoogleAuthProvider();
      const googleResponse = await signInWithPopup(auth, provider);
      await handleUserLoggedIn(googleResponse.user.email as string);
    } catch (error: any) {
      handleFirebaseAuthError(error?.code);
      console.error(error.message);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      props.setIsLoggingIn(true);
      const provider = new GoogleAuthProvider();
      const googleResponse = await signInWithPopup(auth, provider);
      await handleUserLoggedIn(googleResponse.user.email as string);
    } catch (error: any) {
      handleFirebaseAuthError(error?.code);
      console.error(error.message);
    }
  };

  const handleEmailLogin = async () => {
    try {
      props.setIsLoggingIn(true);
      await signInWithEmailAndPassword(auth, email, password);
      await handleUserLoggedIn(email);
    } catch (error: any) {  
      handleFirebaseAuthError(error?.code);
    }
  };

  const handleEmailSignUp = async () => {
    try {
      props.setIsLoggingIn(true);
      await createUserWithEmailAndPassword(auth, email, password);
      await handleUserLoggedIn(email);
    } catch (error: any) {
      handleFirebaseAuthError(error?.code);
      console.error(error.message);
    }
  };

  const handleUserLoggedIn = async (email?: string) => {
    // get user from server
    try{
      const currentFBUser = auth.currentUser;
      const currentUser = await postEndGameApi({url: `/login`, body: {username, email}, path: currentFBUser?.uid});
      if(!currentUser) {
        setErrorMessage('Please sign up for an account');
        signOut(auth);
      } else {
        return props.onSuccess(currentUser);
      }
    } catch(e) {
      signOut(auth);
      setErrorMessage('Please sign up for an account');
    }
    props.setIsLoggingIn(false);
  }

  return (
    <div style={{height: '100vh'}}>
      <div className={props?.modalView ? 'auth-header-modal' : 'auth-header'} onClick={() => {navigate('/')}}>
        <EndGame/>
      </div>
      <div className='auth-body'>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {errorMessage && <p>{errorMessage}</p>}
        {user ? (
          <div>
            <p>Welcome, {user.displayName || user.email}!</p>
            <Button variant='secondary' onClick={() => signOut(auth)}>Sign Out</Button>
          </div>
        ) : (
          <div className="signup-page" style={{height: '100vh'}}>
            <Nav variant='underline' className="justify-content-center" style={{marginBottom: '10px'}}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => toggleTab('1')}
                >
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => toggleTab('2')}
                >
                  Signup
                </NavLink>
              </NavItem>
            </Nav>

            {activeTab==='1' &&
              <Col>
                <Row className='auth-from-row'>
                  <Form.Control type="email" placeholder="Email" className='auth-input'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}/>
                </Row>
                <Row className='auth-from-row'>
                  <Form.Control type="password" placeholder="Password" className='auth-input'
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}/>
                </Row>
                <Row className='auth-from-row'>
                  <EndGameButton className={styles.button} position="relative" text={'Login'} onClick={handleEmailLogin} style={{marginTop: '30px'}}/>
                </Row>
                <Row>
                  <p className={classNames(styles.centerText, {[styles.blackText]: props.modalView})}>---OR---</p>
                </Row>
                <Row className='auth-from-row'>
                  <SignInGoogle style={{cursor: 'pointer'}} onClick={handleGoogleLogin}/>
                </Row>
              </Col>
            }
          
            {activeTab==='2' &&
              <Col>
                <Row className='auth-from-row'>
                <Form.Control type="username" placeholder="Enter Username" className='auth-input'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}/>
                </Row>
                <Row className='auth-from-row'>

                <Form.Control type="email" placeholder="Email" className='auth-input'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                </Row>
                <Row className='auth-from-row'>
                  <Form.Control type="password" placeholder="Password" className='auth-input'
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}/>
                </Row>
                <Row className='auth-from-row'>
                  <Form.Group controlId="agreeCheck" className="checkbox-with-label" style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="d-flex align-items-center" style={{marginRight: '10px'}}>
                      <Form.Check type="checkbox" />
                      <Form.Label className="mb-0">I agree to Terms and Conditions</Form.Label>
                    </div>
                  </Form.Group>
                </Row>
                <Row className='auth-from-row'>
                  <EndGameButton className={styles.button} position='relative' text={'Create Account'} onClick={handleEmailSignUp} style={{marginTop: '30px'}}/>
                </Row>
                <Row>
                  <p className={classNames(styles.centerText, {[styles.blackText]: props.modalView})}>---OR---</p>
                </Row>
                <Row className='auth-from-row'>
                  <SignInGoogle style={{cursor: 'pointer'}} onClick={handleGoogleSignup}/>
                </Row>
              </Col>
            }
          {/* <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="signup-card">
            <Nav tabs className="justify-content-center">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => toggleTab('1')}
                >
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => toggleTab('2')}
                >
                  Signup
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <h4>Login</h4>
                    <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="email" placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Button variant="primary" type="submit" onClick={handleEmailLogin}>Login with Email/Password</Button>
                  </Col>
                  <Col sm="12">
                    <div>or</div>
                  </Col>
                  <Col sm="12">
                  <Button variant="primary" type="submit" onClick={handleGoogleLogin}>Login with Google</Button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <h4>Sign Up</h4>
                    <Form.Group controlId="email">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}/>
                    </Form.Group>
                    <hr/>
                    <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="email" placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={handleEmailSignUp}>Sign Up with Email/Password</Button>
                    <Button variant="primary" type="submit" onClick={handleGoogleSignup}>Sign Up with Google</Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            </div>
            </Col>
          </Row>
        </Container> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginScreen;
