const JSONAutocomplete = require("json-autocomplete");

export const simulationApi = async (
  {
    body, 
    callback, 
    handleFinalScore, 
    cpuMatch
  }:
  {
    body: any, 
    callback: Function, 
    handleFinalScore: Function, 
    cpuMatch: boolean
  }) => {
  try{
    let finalScoreHandled = false;

    const response = await fetch(`${process.env.REACT_APP_STREAM_URL}/${cpuMatch ? 'stream/cpu' : 'stream'}`, {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok || !response.body) {
    throw response.statusText;
  }

  // Here we start prepping for the streaming response
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const loopRunner = true;
  let answer = '';

  while (loopRunner) {
    // Here we start reading the stream, until its done.
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const decodedChunk = decoder.decode(value, { stream: true });
    try{
    answer =  answer + decodedChunk;
    const jsonAnswer = JSON.parse(JSONAutocomplete(answer));
    if(!finalScoreHandled && jsonAnswer?.team1_score && jsonAnswer?.team2_score && jsonAnswer?.highlights_of_the_game) {
      finalScoreHandled = true;
      handleFinalScore(jsonAnswer?.team1_score, jsonAnswer?.team2_score)
    }

    callback(jsonAnswer); 
    } catch(e) {
      console.log(`error parsing`);
    }
  }

  } catch (error) {
    console.error("API request failed:", error);
  }
}