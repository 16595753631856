import classNames from 'classnames';
import { Player } from '../../types/player.interface';
import Price from '../Price';
import styles from './PlayerItem.module.scss';

interface PlayerItemProps {
  className?: string;
  player: Player;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  challengerUsername?: string;
}
/*
  points (on a scale of 0-35 career PPG where 35 is the most and would fill up the green line)
  rebounding (on a scale of 0-20 career RPG where 20 or more would fill up the entire green line)
  playmaking (on a scale of 0-15 career APG where 15 or more would fill up the entire green line)
*/
const PlayerItem: React.FC<PlayerItemProps> = ({
  className, 
  player, 
  isSelected=false, 
  isDisabled, 
  onClick,
  challengerUsername,
}) => {
  const getPointsStat = () => {
    if(player.stats?.pointsPerGame) {
      const stat =  Number(player.stats.pointsPerGame);
      const value = stat / 30 * 100;
      return `${value}%`;
    }
    return 0;
  }

  const getReboundingStat = () => {
    if(player.stats?.reboundsPerGame) {
      const stat =  Number(player.stats.reboundsPerGame);
      const value = stat / 20 * 100;
      return `${value}%`;
    }
    return 0;
  }

  const getPlaymakingStat = () => {
    if(player.stats?.assistsPerGame) {
      const stat = Number(player.stats.assistsPerGame);
      const value = stat / 15 * 100;
      return `${value}%`;
    }
    return 0;
  }

  const countNBAChamps = (str: string) => {
    const multiChampPattern = /(\d+)x NBA Champ/;
    const singleChampPattern = /\d{4} NBA Champ/;
    let multiChampMatch = str.match(multiChampPattern);
    if(multiChampMatch) {
      return parseInt(multiChampMatch[1], 10);
    }
    const singleChampMatch = str.match(singleChampPattern);
    if(singleChampMatch) {
      return 1;
    }
    return 0;
  }

  const getAccolades = () => {
    const accolades = [];
    const mvpRegex = /^\d{4}-\d{2} MVP$/;
    let mvpCount = 0;
    let champCount = 0;
    if(player?.accolades?.length > 0) {
      player.accolades.forEach(acc => {
        if(acc.includes("All Star")) {
          accolades.push(acc);
        }
        if(acc.includes("NBA Champ")) {
          champCount = countNBAChamps(acc);
        }
        if(mvpRegex.test(acc)) {
          mvpCount++;
        }
      }) 
    }
    if(!!mvpCount) {
      accolades.push(`${mvpCount}x MVP`);
    }
    if(!!champCount) {
      accolades.push(`${champCount}x 💍`);
    }
    return accolades;
  }

  const handleClick = () => {
    if(player.claimed) return;
    onClick?.();
  }

  return (
    <div key={player.id} 
      className={classNames(
        styles.playerItem, {
          [styles.selected]: isSelected, 
          disabled: isDisabled,
          [styles.claimedPlayer]: player.claimed
        },
        className
      )}
      onClick={handleClick}
    >
      <div className={styles.playerInfo}>
        <Price price={player.price}/>
        <span>{player.name} {player.claimed ? `${challengerUsername ? (challengerUsername) : ''}` : ''}</span>
      </div>
      <div className={classNames(styles.playerDetails, {[styles.visible]: isSelected})}>
        {player?.headshot && <div className={styles.headshotContainer}>
          <img src={player.headshot} alt={player.name}/>
        </div>}
        <div className={styles.statsContainer}>
          <div className={styles.stats}>
              <div className={styles.statName}>Scoring</div>
              <div className={styles.statValue}>
                <div className={styles.barContainer}>
                  <div className={styles.bar} style={{width: getPointsStat()}}/>
                </div>
              </div>
              <div className={styles.statName}>Playmaking</div>
              <div className={styles.statValue}>
                <div className={styles.barContainer}>
                  <div className={styles.bar} style={{width: getPlaymakingStat()}}/>
                </div>
              </div>
              <div className={styles.statName}>Rebounding</div>
              <div className={styles.statValue}>
                <div className={styles.barContainer}>
                  <div className={styles.bar} style={{width: getReboundingStat()}}/>
                </div>
              </div>
          </div>
          <div className={styles.accolades}>
            {getAccolades().map((accolade, index) => {
              return (<div key={index} className={styles.accolade}>{accolade}</div>)
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerItem;