import { useEffect, useState } from 'react';
import { Button, Toast } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import { patchEndGameApi, postEndGameApi } from '../../helpers/endgameApi';
import TeamToBeatList from '../../scenes/home/TeamToBeat/TeamToBeat';
import Play from '../Play/Play';
import BoxScoreResponse, { TeamStats } from '../../scenes/home/BoxScoreResponse/BoxScoreResponse';
import { dailyChallengeSimulationApi } from '../../helpers/dailyChallengeSimulationApi';
import { CurrentUserPlayer, MatchStatus } from '../../constants';
import { AuthModal } from "../AuthModal";
import EndGameButton from '../EndgameButton/EndGameButton';
import { Footer } from '../Footer';
import Header from '../Header/Header.component';
import styles from './Match.module.scss';

export const DailyMatch = (props: any) => {
  const currentUser = props.currentUser;
  const params = useParams();
  const [matchId, setMatchId] = useState<string>('');
  const [loadingMatch, setLoadingMatch] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [errorLoadingMatchMessage, setErrorLoadingMatchMessage] = useState<string>('');
  const [userALineup, setUserALineup] = useState<any[]>([]);
  const [userBLineup, setUserBLineup] = useState<any[]>([]);
  const [matchData, setMatchData] = useState<any>(null);
  const [simResponseText, setSimResponseText] = useState<TeamStats | undefined>();
  const [userBLineupSubmitted, setUserBLineupSubmitted] = useState<boolean>(false);
  const [currentUserPlayer, setCurrentUserPlayer] = useState<CurrentUserPlayer>(CurrentUserPlayer.NONE);
  const [hasHandledFinalScore, setHasHandledFinalScore] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [isBeginningMatch, setIsBeginningMatch] = useState<boolean>(false);
  const [dots, setDots] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>('');
  const [privateMatch, setPrivateMatch] = useState<boolean>(false);
  const [leaderboardRank, setLeaderboardRank] = useState<number | undefined>(undefined);
  const [leaderboardEntriesCount, setLeaderboardEntriesCount] = useState<number | undefined>(undefined);


  const navigator = useNavigate();

  let currentMatchId = '';
  useEffect(() => {
    const loadMatchData = async (newMatchId: string) => {
        try{
        setLoadingMatch(true);
        setMatchId(params.matchId as string);
        const match = await postEndGameApi({url: `/matches`, path: params.matchId});
        if(match) {
          setErrorLoadingMatchMessage('')
          setMatchData(match);
          const tmpUserALineup =  match?.userALinup?.players ? JSON.parse(match?.userALinup?.players) : [];
          setUserALineup(tmpUserALineup);
          const tmpUserBLineup =  match?.userBLinup?.players ? JSON.parse(match?.userBLinup?.players) : [];
          if(tmpUserBLineup.length) {
            setUserBLineup(tmpUserBLineup);
          }

          // check if the match is open and if it is free - if so call the simulation function
          if(match.status === 'open' && match.isCpuMatch) {
            handleSubmitCpuChallenge(params.matchId as string);
          }
        }
        } catch(e) {
          setErrorLoadingMatchMessage('Error loading match')
        }
      setInitializing(false);
      setLoadingMatch(false);
    }

    // load match data
    if((!currentMatchId || currentMatchId !==params.matchId) && params.matchId) {
      currentMatchId = params.matchId;
      loadMatchData(params.matchId as string);
    }
  }, [params.matchId]);

  useEffect(() => {
    if(matchData) {
      if(currentUser?.id === matchData.userAId) {
        setCurrentUserPlayer(CurrentUserPlayer.A)
      }else if(currentUser?.id === matchData.userBId) {
        setCurrentUserPlayer(CurrentUserPlayer.B)
      } else {
        setCurrentUserPlayer(CurrentUserPlayer.NONE)
      }
    }
  }, [currentUser, matchData]);

  useEffect(() => {
    if(isBeginningMatch) {
    const interval = setInterval(() => {
      // Update dots based on current state
      setDots((prevDots) => {
        if (prevDots === '') return '.';
        if (prevDots === '.') return '..';
        if (prevDots === '..') return '...';
        return '';
      });
    }, 500);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }
  }, [isBeginningMatch]);

  const handleStream = async (text: TeamStats) => {
      setIsBeginningMatch(false)
    if (text) {
      setSimResponseText(text as TeamStats);
    }
  };

  const handleFinalScore = () => {
    setHasHandledFinalScore(true)
  }

  const handleLeaderboardResult = async (team1Score: number, team2Score: number) => {
    console.log('leaderboard result handled');
    const differential = team1Score - team2Score;

    const rankResponse = await postEndGameApi({url: `/leaderboards/active/rank`, body: {differential}});
    console.log(rankResponse);
    setLeaderboardRank(rankResponse?.rank);
    setLeaderboardEntriesCount(rankResponse?.entriesCount);
  }

  const handleSubmitChallenge = async (userBSelectedPlayers: any[], user?: any) => {
    await setUserBLineup(userBSelectedPlayers);
    await setUserBLineupSubmitted(true);
    if(currentUser) {
      await props.handleChangeUser((prevData: any) => ({...prevData, availableBalance: prevData.availableBalance - (matchData.amount as number)}))
    }

    setIsBeginningMatch(true);
    try {
    dailyChallengeSimulationApi({body: {
      matchId
    }, callback: handleStream, handleFinalScore, handleLeaderboardResult})
  } catch (e) {
    const errorString = `There was an error simulating the match ${matchData.amount ? ',  Both players have been refunded': ''}`
    alert(errorString)
  }
  }

  const handleSubmitCpuChallenge = async (matchId: string) => {
    setIsBeginningMatch(true);
     dailyChallengeSimulationApi({body: {
      matchId,
    }, callback: handleStream, handleFinalScore, handleLeaderboardResult})
  }
  

  const onAuthSuccess = async (newUser: any) => {
    await props.handleChangeUser(newUser);
    setShowAuthModal(false);
  }

  const copyLink = async (link: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = link;
    textarea.style.position = 'fixed'; // Ensure textarea is not displayed
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
      document.execCommand('copy');
      setShowToast(true);
    } catch (error) {
      console.error('Error copying URL to clipboard:', error);
    } finally {
      document.body.removeChild(textarea); // Clean up
    }
  };

  const makeMatchPrivate = async () => {
    try{
      await patchEndGameApi({url: `/matches/${matchId}`, body: {public: false}});
      setToastText('Match now private');
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false)
        setToastText('');
      }, 3000);
      setPrivateMatch(true);
      const existingMatchIndex = props.currentUser?.matches?.findIndex((x: any) => x.id === matchId);
      const newMatches = props.currentUser?.matches;
      newMatches?.splice(existingMatchIndex, 1, {...props.currentUser?.matches[existingMatchIndex], public: false});
      props.handleChangeUser({...props.currentUser, matches: newMatches});
    } catch(e) {
      console.log('failed to complete change')
    }
  }

  return (
    <div style={{textAlign: 'left'}}>
      <Toast show={showSuccessToast} onClose={() => setShowSuccessToast(false)} className='success-toast'>
        <Toast.Body>{toastText}</Toast.Body>
      </Toast>
      <AuthModal show={showAuthModal} handleClose={() => {setShowAuthModal(false)}} onAuthSuccess={(newUser: any) =>onAuthSuccess(newUser)} setIsLoggingIn={(isLoggingIn: boolean) => props.setIsLoggingIn(isLoggingIn)}/>
      {errorLoadingMatchMessage && errorLoadingMatchMessage}
      {matchData && !loadingMatch && matchData.status === 'simulating' && 
        <h1>Match is Simulating, check back soon.</h1>
      }
      {isBeginningMatch && 
        <div>
        <h1>Beginning Match{dots}</h1>
        </div>
      }
      {matchData && matchData.status === MatchStatus.ERRORED && <h3 style={{marginBottom: '50px'}}>There was an error simulating the match{matchData.amount ? ',  Both players have been refunded': '. Please try again later.'}</h3>}
      {matchData && !loadingMatch && !isBeginningMatch && matchData.status !== 'simulating' &&
        <div style={{display: props.isLoadingCurrentUser ? 'none' : 'block'}} className='testme'>
          {currentUserPlayer === CurrentUserPlayer.A ? (
            <></>
            ) : (!matchData?.simulated && !userBLineupSubmitted) 
            ? <div className={styles.matchChallengeBanner}>
              <div>{matchData?.userA?.username} has challenged you to a {matchData.amount > 0 ? `$${matchData.amount}` : 'Free'} match!</div>
              <div>Pick your lineup and see who wins!</div>
              </div> : <></>
          }
          {(currentUserPlayer === CurrentUserPlayer.A && !matchData?.simulated && !simResponseText) && <Header>Your lineup is IN...</Header>}
          {userALineup && !simResponseText && (currentUserPlayer === CurrentUserPlayer.A && (!matchData?.simulated && !userBLineupSubmitted)) && <TeamToBeatList teamsToBeat={userALineup} />}

          {matchData?.userBId && currentUserPlayer === CurrentUserPlayer.B && (!matchData?.simulated && !userBLineupSubmitted) && (
            <p>Your Lineup</p>
            )
          }
          {matchData?.userBId && userBLineup && (!matchData?.simulated && !userBLineupSubmitted) && !simResponseText && <TeamToBeatList teamsToBeat={userBLineup} />}

          {!matchData?.simulated || !userBLineupSubmitted}

          {!matchData?.userBId && currentUserPlayer !== CurrentUserPlayer.A && !userBLineupSubmitted && (
            <div style={{display: props.isLoadingCurrentUser ? 'none' : 'block'}}>
              <Play currentUser={currentUser} handleChangeUser={props.handleChangeUser} 
              matchData={matchData} 
              challengeAcceptedHandler={(userBSelectedPlayers: any[], authuser: any) =>{handleSubmitChallenge(userBSelectedPlayers, authuser);}}
              setIsLoggingIn={(isLoggingIn: boolean) => props.setIsLoggingIn(isLoggingIn)}
              />
            </div>
          )}
          {!matchData?.userBId && currentUserPlayer === CurrentUserPlayer.A && (
            <>
              <Header className={styles.shareHeader}>Share your Game URL to challenge a friend.</Header>
              <div className={styles.linkContainer}>
                <div className={styles.link}>{`${window.location.origin.toString()}/match/${matchId}`}</div>
                <EndGameButton className={styles.button} onClick={() => copyLink(`${window.location.origin.toString()}/match/${matchId}`)} text="Copy Link" position='relative'/>
              </div>
              <>
                <Toast show={showToast} onClose={() => setShowToast(false)}>
                  <Toast.Header>
                    <strong className="mr-auto">Link Copied!</strong>
                  </Toast.Header>
                  <Toast.Body>{`${window.location.origin.toString()}/match/${matchId}`}</Toast.Body>
                </Toast>
              </>
              {privateMatch 
              ? <div style={{marginTop: '30px'}}>Match now private - you can change it back to public in your profile</div>
              : <>
                  {matchData.amount > 0 ? <>
                    <div style={{marginTop: '30px'}}>Your game is also featured in EndGame's open matches for others to see and accept</div>
                    <Button variant='link' style={{paddingLeft: '0px'}} onClick={() => {makeMatchPrivate()}}>Change match to private</Button>
                  </>
                  : <div style={{marginTop: '30px'}}>Note: Free games will not show up in the open matches lobby</div>}
                </>
              }
            </>
          )}
          {(matchData?.simulated || userBLineupSubmitted || simResponseText) && 
            <BoxScoreResponse 
              data={simResponseText ? {matchGptOutput: simResponseText, userA: matchData.userA, userB: matchData.isCpuMatch ? matchData.userB : props?.currentUser} : matchData} 
              currentUser={props?.currentUser} 
              currentUserPlayer={currentUserPlayer} 
              userALineup={userALineup} 
              userBLineup={userBLineup}
              rank={leaderboardRank}
              leaderboardEntriesCount={leaderboardEntriesCount}
            />
          }
          {(matchData?.simulated || hasHandledFinalScore) && (currentUserPlayer === CurrentUserPlayer.A) &&
            <>
              <EndGameButton position='relative' onClick={() =>{navigator('/daily/leaderboard')}} text='View Leaderboard'/>
              <p className='footer-promo'>Take a screenshot of the results, share on social and tag @playendgameai to receive $5 in credits</p>
              <Footer/>
            </>
          }
        </div> 
      }
      {!matchData && !initializing && <h3>No match found. This match may have been canceled</h3>}
    </div>
  );
};
