import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DAILY_LINEUP_CHALLENGE_BUY_IN } from "../../constants";
import { endGameApi, postEndGameApi } from "../../helpers/endgameApi";
import PlayersToChooseFrom from "../../scenes/home/PlayersToChooseFrom/PlayersToChooseFrom";
import { Player } from "../../types/player.interface";
import { AuthModal } from "../AuthModal";
import EndGameButton from "../EndgameButton/EndGameButton";
import Header from "../Header";
import Lineup from "../Lineup";
import styles from "./DailyChallenge.module.scss";
import { PaymentModal } from "../PaymentModal";

interface DailyLineupChallengeProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
  setIsLoggingIn:(isLoggingIn: boolean) => void;
}

const MAX_PRICE = 15;

const DailyLineupChallenge = ({
  currentUser,
  handleChangeUser,
  setIsLoggingIn
}: DailyLineupChallengeProps) => {
  const navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [cpuPlayers, setCpuPlayers] = useState([]);
  const [currentPot, setCurrentPot] = useState(0);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [budgetUsed, setBudgetUsed] = useState<number>(0);
  const [lineupComplete, setLineupComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const pot = currentPot.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  async function getPlayers() {
    try{
      const availablePlayerPool = await endGameApi('get', '/daily-challenge/available-players');
      setPlayers(availablePlayerPool?.availablePlayers);
    } catch(e) {

    }
  }

  async function getCpuDailyLineup() {
    try {
      const cpuLineup = await endGameApi('get', '/daily-challenge/cpu-lineup');
      if(cpuLineup?.players) {
        setCpuPlayers(JSON.parse(cpuLineup.players));
      }
    } catch(e) {}
  }

  async function getCurrentPot() {
    try {
      const currentPot = await endGameApi('get', '/daily-challenge/current-pot');
      setCurrentPot(currentPot?.amount || 0);
    } catch(e) {}
  }

  useEffect(() => {
    getPlayers();
    getCpuDailyLineup();
    getCurrentPot();
  }, []);


  const handleLockIn = () => {
    if (selectedPlayers.length < 5) {
      alert("Select a player from each position");
      return;
    }

    if (budgetUsed>MAX_PRICE) {
      return;
    }

    if(!currentUser?.id) {
      return setShowAuthModal(true);
    }

    setLineupComplete(true)
  }

  const handlePlayerToggle = (player: Player) => {
    // Check if the player is already selected
    const isPlayerSelected = selectedPlayers.some((p) => p.id === player.id);
    const currentPositionPlayer = selectedPlayers.find(x =>x.position === player.position);

    let newTotalPrice;

    if(isPlayerSelected) {
      newTotalPrice = selectedPlayers.reduce((total, p) => total + p.price, 0) - player.price
    } else {
      const tempSelectedPLayers = selectedPlayers;
      newTotalPrice =tempSelectedPLayers.reduce((total, p) => total + p.price, 0) + player.price - (currentPositionPlayer?.price ?? 0);
    }
    setBudgetUsed(newTotalPrice);

    // Create a new list of updated selected players
    const updatedSelectedPlayers = isPlayerSelected
      ? selectedPlayers.filter((p) => p.id !== player.id) // Remove the player
      : [...selectedPlayers, player].filter((p) => p.id !== currentPositionPlayer?.id); // Add the player, remove the current player in position if there is one

    // Update the state with the updated list
    setSelectedPlayers(updatedSelectedPlayers);
  };

  const onAuthSuccess = async (newUser: any) => {
    await handleChangeUser(newUser);
    setShowAuthModal(false);
    setLineupComplete(true)
  }

  const submitLineup = async () => {
    setLoading(true);

    if(currentUser?.availableBalance < DAILY_LINEUP_CHALLENGE_BUY_IN) {
      setLoading(false);
      return setShowPaymentModal(true);
    }

    try {
      const matchResponse = await postEndGameApi({url: '/daily-challenge/matches', body: { selectedPlayers }});
      await handleChangeUser(matchResponse.updatedUser);
      navigate(`/daily/match/${matchResponse.matchId}`);
    } catch (e) {
      console.log(`error occurred when creating match: ${e}`);
      alert('error occurred when creating match');
    } finally {
    }
  }

  const depositSuccess = async () => {
    setShowPaymentModal(false);
     try {
      const matchResponse = await postEndGameApi({url: '/daily-challenge/matches', body: { selectedPlayers }});
      await handleChangeUser(matchResponse.updatedUser);
      navigate(`/daily/match/${matchResponse.matchId}`);
    } catch (e) {
      console.log(`error occurred when creating match: ${e}`);
      alert('error occurred when creating match, make sure you have enough balance');
    }
  }

  return <div className={styles.container}>
    <PaymentModal show={showPaymentModal} 
      handleClose={() => {setShowPaymentModal(false)}} 
      headerContent={'Make a deposit to play for $. You can withdraw your $ anytime.'}
      depositSuccess={depositSuccess}
      handleChangeUser={handleChangeUser}/>
    <AuthModal 
      show={showAuthModal} handleClose={() => {setShowAuthModal(false)}} 
      onAuthSuccess={(newUser: any) =>onAuthSuccess(newUser)} 
      setIsLoggingIn={(isLoggingIn: boolean) => setIsLoggingIn(isLoggingIn)}
    />
    {
      lineupComplete && <>
        <Header>Your Lineup Is Ready!</Header>
        <br />
        <br />
        <h3 className={styles.subHeader}>Your Squad:</h3>
        <Lineup players={selectedPlayers}/>
        <h3 className={styles.subHeader}>Playing Against:</h3>
        {/* TODO: change Lineup players prop to players playing against */}
        <Lineup players={cpuPlayers}/>
        <div><span className={styles.moneyText}>{`Buy In: Free`}</span></div>
        {/* <div><span className={styles.moneyText}>{`Buy In: $${DAILY_LINEUP_CHALLENGE_BUY_IN}`}</span></div> */}
        <div><span className={styles.moneyText}>{`Current Pot: NA`}</span></div>
        {/* <div><span className={styles.moneyText}>{`Current Pot: ${pot}`}</span></div> */}
        <EndGameButton 
          className={styles.button}
          text="Submit My Squad!"
          position='relative' 
          onClick={submitLineup} 
          disabled={loading}
        />
      </>
    }
    {!lineupComplete && <>
      <Header>Daily Lineup Challenge</Header>
      <Lineup players={cpuPlayers} />
      <p className={styles.description}>Build a lineup to challenge the EndGame Daily Team to Beat</p>
      <div><span className={styles.moneyText}>{`Buy In: Free`}</span></div>
      {/* <div><span className={styles.moneyText}>{`Buy In: $${DAILY_LINEUP_CHALLENGE_BUY_IN}`}</span></div> */}
      <div>
        <span className={styles.moneyText}>{`Current Pot: NA`}</span> 
        {/* <span className={styles.moneyText}>{`Current Pot: ${pot}`}</span>  */}
        <Link className={styles.link} to="/daily/leaderboard">View Leaderboard</Link>
        <br />
        <br />
        <PlayersToChooseFrom 
          players={players}
          claimedPlayers={cpuPlayers}
          handlePlayerToggle={handlePlayerToggle}
          selectedPlayers={selectedPlayers}
          maxPrice={MAX_PRICE}
          isDailyLineupChallenge
        />
        <EndGameButton text="Lock In My Team" onClick={handleLockIn}/>
      </div>
    </>}
  </div>
}

export default DailyLineupChallenge;