
import { useState } from 'react';
import { Modal, Row, Col, Button, Spinner } from 'react-bootstrap';
import { ReactComponent as EndGame } from '../../assets/EndGame.svg';
import { Form } from 'react-bootstrap';
import { postEndGameApi } from '../../helpers/endgameApi';
import styles from './RequestWithdrawalModal.module.scss';

interface RequestWithdrawProps {
  show: boolean;
  currentUser: any;
  handleClose: () => void;
  handleChangeUser: (newUser: any) => void;
  withdrawRequestSuccess: (amount: number) => void
}


const RequestWithdrawModal = (props: RequestWithdrawProps) => {
  const [withdrawAmount, setWithdrawAmount] = useState('0');
  const [errorMessage, setErrorMessage] = useState('');
  const [withdrawMethod, setWithdrawMethod] = useState('paypal');
  const [accountName, setAccountName] = useState('');
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const changeWithdrawValue = (e: any) => {
    let newValue = e.target.value;
    if(newValue === '') {
      setWithdrawAmount('0')
    } else {
      if(newValue[0] === '0') {
        newValue = newValue.substring(1);
      }
      newValue = newValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot
      let parts = newValue.split('.');
      if (parts.length > 2) {
          parts.pop();
      }
      if (parts.length === 2) {
          parts[1] = parts[1].slice(0, 2); // Keep only two decimal places
      }

      const parsedVal = parts.join('.');
  
      setWithdrawAmount(parsedVal)
    }
  }


  const handleWithdrawRequest = async () => {
    const floatAmount = parseFloat(withdrawAmount)
    if(floatAmount < 20) {
      setErrorMessage('Withdraw amount must be at least $20');
    }
    else if(!accountName) {
      setErrorMessage('Please set account name ');
    }
    else if (floatAmount > props.currentUser.availableBalance) {
      setErrorMessage('Not enough balance');
    } else {
      setErrorMessage('');
      try{
        setIsSendingRequest(true);
      await postEndGameApi({url: '/withdraw-request', body: {
        amount: floatAmount,
        accountName,
        type: withdrawMethod
      }});
      setIsSendingRequest(false);

      props.withdrawRequestSuccess(floatAmount);
    } catch(e) {
      setErrorMessage('There was an error processing your request');
    }
    }
  }

  return (
    <Modal className={styles.modal} show={props.show} onHide={props.handleClose } centered>
      <Modal.Header closeButton className={styles.modalHeader}>
        <h4><strong>Withdraw Funds</strong></h4>
      </Modal.Header>
      <Modal.Body>
        <div className='auth-header-no-margin'>
          <EndGame/>
        </div>
        <div style={{display: 'flex'}}>
          <Col xs={12}>
            <Row>
              <p>Please allow up to 48 hours for your withdrawal to process.</p>
              <p>Current available balance: <strong>${props.currentUser?.availableBalance?.toFixed(2) || 0}</strong></p>
            </Row>
            <Row style={{justifyContent: 'center'}}>
              <input onChange={changeWithdrawValue} className={styles.amount} type="text" id="withdrawal-amount" value={withdrawAmount}/>
            </Row>
          </Col>
        </div>
        <div style={{marginTop: '50px'}}>
          <Form.Check
            inline
            type="radio"
            label="Paypal"
            id="paypal"
            name="paymentMethod"
            value="paypal"
            checked={withdrawMethod === 'paypal'}
            onChange={() =>setWithdrawMethod('paypal')}
            className={styles.radio}
          />
          <Form.Check
            inline
            type="radio"
            label="Venmo"
            id="venmo"
            name="paymentMethod"
            value="venmo"
            checked={withdrawMethod === 'venmo'}
            onChange={() =>setWithdrawMethod('venmo')}
            className={styles.radio}
          />
        </div>
        <div style={{marginTop: '10px'}}>
          <span className={styles.capitalize}>{withdrawMethod}</span> Account name: 
          <Form.Control type="email" placeholder="Account Name" className='auth-input'
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}/>
        </div>
        <p style={{color: 'red'}}>{errorMessage}</p>
        <Button disabled={isSendingRequest} className={styles.button} onClick={() => handleWithdrawRequest()}>
          {isSendingRequest ? <Spinner animation="border" size="sm" /> : 'Withdraw Funds'}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default RequestWithdrawModal;