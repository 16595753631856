import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Image } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL, StorageReference } from "firebase/storage";
import { postEndGameApi } from '../../helpers/endgameApi';
import greyCircle from '../../assets/grey-circle.svg.hi.png';

interface ProfileProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
}

const ProfilePicture = (props: ProfileProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showEditForm, setShowEditForm] = useState(false);
const storage = getStorage();


  const handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    setShowEditForm(false);

    const storageRef = ref(storage, `profile-pictures/${props.currentUser.id}`);
  
    try{
    const snapshot = await uploadBytes(storageRef, file);

    const url = await getDownloadURL(snapshot?.ref as StorageReference);

    await postEndGameApi({url: `/users`, body: {profilePictureUrl: url}});
    setSelectedImage(url);
    props.handleChangeUser({...props.currentUser, profilePictureUrl: url})
  } catch(error) {
    console.error(error);
    alert('failed to save new profile picture');
  }
  };

  return (
    <div className="text-center">
      <Image
        src={selectedImage || props.currentUser?.profilePictureUrl || greyCircle}
        style={{ borderRadius: '50%', cursor: 'pointer' }}
        width={150}
        height={150}
        onClick={() => setShowEditForm(true)}
      />
      <div>
        <small className="text-muted">
          <a href="#" onClick={() => setShowEditForm(true)}>Edit Profile Picture</a>
        </small>
      </div>
      {showEditForm && (
        <Form.Group controlId="profilePicture">
          <Form.Label>Choose a Picture</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} accept="image/*" />
        </Form.Group>
      )}
    </div>
  );
};

export default ProfilePicture;